<template>
  <BPCard
    dataTestId="payables-table"
    :headerConfig="headerConfig"
    :contractMode.sync="contractMode"
    hideNonTract
  >
    <v-row v-if="!loading">
      <v-col cols="12">
        <v-row class="mx-1">
          <v-col cols="auto">
            <EntitySelector
            :entities="Object.keys(entities).map(n => ({ name: n }))"
            :initialEntity="selectedEntity"
            @entity-selected="entitySelected"
            />
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto" class="mt-4">
            <table>
              <tr class="headline font-weight-bold">
                <td>{{$t('total')}}:</td>
                <td>{{formatMoney(totals.grandTotal)}}</td>
              </tr>
              <tr v-for="(tt, i) in totalsTable" :key="i" class="title">
                <td>{{$t(tt.text)}}:</td>
                <td class="text-right">{{formatMoney(totals[tt.key])}}</td>
              </tr>
            </table>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-expansion-panels popout multiple tile v-model="openPanels">
          <v-expansion-panel>
            <v-expansion-panel-header>
              {{$t('ticketPayables')}}
              <template #actions>
                {{formatMoney(totals.ticketPayables)}}
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <CurrentPayables
              :propPayables="itemsByEntity.ticketPayables"
              :contractMode="contractMode"/>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel v-if="!isByproducts">
            <v-expansion-panel-header>
              {{$t('transferPayables')}}
              <template #actions>
                {{formatMoney(totals.transferPayables)}}
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <CurrentPayables
              :propPayables="itemsByEntity.transferPayables"
              :contractMode="ContractMode.Transfers"/>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel v-if="!isByproducts">
            <v-expansion-panel-header>
              {{$t('logYardSalePayables')}}
              <template #actions>
                {{formatMoney(totals.logYardSalePayables)}}
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <CurrentPayables
              :propPayables="itemsByEntity.logYardSalePayables"
              :contractMode="ContractMode.LogYardSale"/>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel v-if="!isByproducts">
            <v-expansion-panel-header>
              {{ $t('miscPayments') }}
              <template #actions>
                {{ formatMoney(totals.miscPayables) }}
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="mt-8">
                <v-col>
                  <span class="title">{{ $t('tractPayables') }}</span>
                  <CurrentMiscFinancials
                  :payables="itemsByEntity.tractPayables"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span class="title">{{ $t('advances') }}</span>
                  <CurrentAdvances
                  :advances="itemsByEntity.advances"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span class="title">{{ $t('accountPayments') }}</span>
                  <CurrentMiscFinancials
                  :payables="itemsByEntity.accountPayablesAP"/>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <Loading v-else></Loading>
  </BPCard>
</template>

<script>
import { LocalStorageKeys } from '@/utils/LocalStorageActor'
import { mapActions, mapGetters } from 'vuex'
import { formatMoney } from '@/utils/NumericMutations'
import { ContractMode, ContractType } from '@/utils/Enumerations.js'
export default {
  name: 'AccountsPayable',

  components: {
    EntitySelector: () => import('@/components/accounting/EntitySelector.vue'),
    CurrentPayables: () => import('@/components/accounting/accounts-payable/current-payables/CurrentPayables.vue'),
    CurrentAdvances: () => import('@/components/accounting/accounts-payable/current-payables/CurrentAdvances.vue'),
    CurrentMiscFinancials: () => import('@/components/accounting/accounts-payable/current-payables/CurrentMiscFinancials.vue'),
    Loading: () => import('@/components/core/Loading.vue'),
    BPCard: () => import('@/components/core/BPCard.vue')
  },

  data: () => ({
    ContractMode,
    selectedEntity: undefined,
    loading: false,
    openPanels: [],
    contractMode: null
  }),

  computed: {
    ...mapGetters('payable', ['entities', 'entityNames']),

    headerConfig () {
      switch (this.contractMode?.value) {
        case 0: return { title: this.$t('currentPayables'), subtitle: this.$t('payablesSubtitle') }
        case 1: return { title: this.$t('currentByProductPayables'), subtitle: this.$t('BPPayablesSubtitle') }
        case 2: return { title: this.$t('transferTicketPayables'), subtitle: this.$t('transferTicketPayableDescription') }
      }

      return null
    },

    itemsByEntity () {
      if (!this.selectedEntity) {
        return {
          ticketPayables: [],
          transferPayables: [],
          logYardSalePayables: [],
          advances: [],
          tractPayables: [],
          accountPayablesAP: []
        }
      }
      const itemsByEntity = this.entities[this.selectedEntity.name]
      return {
        ticketPayables: (!this.isByproducts)
          ? itemsByEntity.payables.filter(p => p.contractType === ContractType.Production.value || p.contractType === ContractType.WoodsSale.value)
          : itemsByEntity.payables.filter(p => p.contractType === ContractType.ByproductSale.value || p.contractType === ContractType.ByproductPurchase.value),
        transferPayables: itemsByEntity.payables.filter(p => p.contractType === ContractType.Transfer.value),
        logYardSalePayables: itemsByEntity.payables.filter(p => p.contractType === ContractType.LogYardSale.value),
        advances: !this.isByproducts ? itemsByEntity.advances : [],
        tractPayables: !this.isByproducts ? itemsByEntity.tractPayables : [],
        accountPayablesAP: !this.isByproducts ? itemsByEntity.accountPayablesAP : []
      }
    },

    isLogsContract () {
      return this.contractMode?.value === 0 ?? true
    },

    isByproducts () {
      return this.contractMode?.value === 1
    },

    totals () {
      const sumEntity = (a, b) => a + b.amount

      const ticketPayables = (this.itemsByEntity?.ticketPayables ?? []).reduce(sumEntity, 0)
      const transferPayables = (this.itemsByEntity?.transferPayables ?? []).reduce(sumEntity, 0)
      const logYardSalePayables = (this.itemsByEntity?.logYardSalePayables ?? []).reduce(sumEntity, 0)
      const tractPayables = (this.itemsByEntity?.tractPayables ?? []).reduce(sumEntity, 0)
      const advances = (this.itemsByEntity?.advances ?? []).reduce(sumEntity, 0)
      const accountPayablesAP = (this.itemsByEntity?.accountPayablesAP ?? []).reduce(sumEntity, 0)
      const miscPayables = tractPayables + advances + accountPayablesAP

      const grandTotal = ticketPayables + transferPayables + logYardSalePayables + tractPayables + advances + accountPayablesAP

      return {
        ticketPayables,
        transferPayables,
        logYardSalePayables,
        tractPayables,
        advances,
        accountPayablesAP,
        miscPayables,
        grandTotal
      }
    },

    totalsTable () {
      return (!this.isByproducts)
        ? [
          { text: 'tickets', key: 'ticketPayables' },
          { text: 'transfers', key: 'transferPayables' },
          { text: 'logYardSale', key: 'logYardSalePayables' },
          { text: 'tracts', key: 'tractPayables' },
          { text: 'advances', key: 'advances' },
          { text: 'accountPayments', key: 'accountPayablesAP' }
        ] : []
    }
  },

  watch: {
    contractMode (cm) {
      localStorage.setItem(LocalStorageKeys.AP_CONTRACT_MODE, JSON.stringify(cm.value))
      if (cm.value === ContractMode.Byproducts.value) {
        this.openPanels = [0]
      } else {
        this.openPanels = []
      }
      this.refresh()
    }
  },

  created () {
    const cookieValue = parseInt(localStorage.getItem(LocalStorageKeys.AP_CONTRACT_MODE)) || undefined
    this.contractMode = (cookieValue && ContractMode.forInt(cookieValue)) ?? ContractMode.Logs
    if (this.contractMode?.value === ContractMode.Byproducts.value) {
      this.openPanels = [0]
    }
  },

  methods: {
    ...mapActions('payable', ['fetchPayables', 'fetchAllTractPayables', 'resetState', 'fetchUnpaidAdvances', 'fetchUnpaidAccountPayablesAP']),
    entitySelected (entity) { this.selectedEntity = entity },
    formatMoney,
    async refresh () {
      this.loading = true
      this.resetState()
      try {
        await Promise.all([
          this.fetchPayables(this.contractMode),
          !this.isByproducts ? this.fetchAllTractPayables() : undefined,
          !this.isByproducts ? this.fetchUnpaidAdvances() : undefined,
          !this.isByproducts ? this.fetchUnpaidAccountPayablesAP() : undefined
        ])

        this.selectedEntity = undefined
        const entities = Object.keys(this.entities)

        if (entities.length > 0) {
          this.selectedEntity = { name: entities[0] }
        }
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
